import { sendGetRequest, sendPostRequest } from "./service"

export const listSeoRequest = async() => {
    return await sendGetRequest("/api/private/seo", {
        pageIndex: 0,
        pageSize: 10
    })
}

export const updateSeoRequest = async(id, seoId, type, active) => {
    let params = {
        id: id,
        seoId: seoId,
        type: type,
        active: active
    }
    return await sendPostRequest("put", "/api/private/seo", params);
}