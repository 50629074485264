import React, { useState } from "react";
import { Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserPen } from "@fortawesome/free-solid-svg-icons";
import Notification from "../components/Notification"
import PaginationComp from "../components/PaginationComp";
import {DateTime} from "luxon";

import "../assets/css/rc-table.scss"
import { createAccountRequest, listUserRequest, resetPasswordRequest } from "../services/userService";
import { useEffect } from "react";
import Header from "../layouts/Header";

export default function UserManagement(){
    const [users, setUsers] = useState([]);

    //search filter
    const [username, setUsername] = useState("");
    const [fullName, setFullName] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [totalItem, setTotalItem] = useState(0);

    const [createAccountDialog, setCreateAccountDialog] = useState(false);
    const [newUsername, setNewUsername] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newFullName, setNewFullName] = useState("");

    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [resetPassword, setResetPassword] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");

    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }

    const onSearchClick = async() =>{
        const data = await listUserRequest(username, fullName, null, null, pageIndex, pageSize);
        if(data.isError){
            displayNotification("Can not load users", "danger");
            return;
        }
        setTotalItem(data.total);        
        setUsers(data.items);
    }
    useEffect(() => {
        onSearchClick();
    }, [pageIndex])
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }

    const closeCreateAccountDialog = () => {
        setCreateAccountDialog(false);
        setNewUsername("");
        setNewEmail("");
        setNewPassword("");
        setNewFullName("")
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            onSearchClick();
        }
    }
    const closeResetPasswordDialog = () => {
        setResetPasswordDialog(false);
        setResetPassword("");
    }

    const createAccount = async() => {
        if(newUsername === ""){
            displayNotification("Tên đăng nhập không hợp lệ", "danger");
            return;
        }
        let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!newEmail.match(emailRegex)){
            displayNotification("Email không hợp lệ", "danger");
            return;
        }
        if(newPassword === ""){
            displayNotification("Mật khẩu không được để trống", "danger");
            return;
        }
        const data = await createAccountRequest(newUsername, newEmail, newPassword, newFullName);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCreateAccountDialog();
    }

    const updatePassword = async() => {
        if(resetPassword === ""){
            displayNotification("Mật khẩu không được để trống", "danger");
            return;
        }
        const data = await resetPasswordRequest(selectedUserId, resetPassword);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeResetPasswordDialog();
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row className="g-3">
                        <Col md={2}>
                            Tên đăng nhập:
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" placeholder="testuser" onChange={e => setUsername(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2}>
                            Họ tên:
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" placeholder="testuser" onChange={e => setFullName(e.target.value)}/>
                        </Col>
                        <Col md={2}>
                            <Button variant="primary" onClick={e => {
                                if(pageIndex !== 0){
                                    setPageIndex(0);
                                }else{
                                    onSearchClick();
                                }
                            }}>Tìm kiếm</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>Danh sách người dùng:</Col>
                        <Col md={8}></Col>
                        <Col md={2} style={{fontWeight: "bold", textAlign: "right"}}>Tổng số: {totalItem}</Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12} style={{textAlign: "right"}}>
                            <Button variant="success" onClick={e => setCreateAccountDialog(true)}>Thêm mới</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>
                                            Tên đăng nhập
                                        </th>
                                        <th>
                                            Tên đầy đủ
                                        </th>
                                        <th>
                                            Ngày tạo
                                        </th>
                                        <th>
                                            Trạng thái
                                        </th>
                                        <th style={{textAlign: "center"}}>
                                            Reset password
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && 
                                        <>
                                            {users.map((user) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{user.username}</td>
                                                            <td>{user.fullName}</td>
                                                            <td>{(DateTime.fromISO(user.createdAt)).toISODate(DateTime.DATE_SHORT)}</td>
                                                            <td>
                                                                {user.status ? "active" : "inactive"}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faEdit} size="lg" onClick={ e => {
                                                                    setSelectedUserId(user.id);
                                                                    setResetPasswordDialog(true)
                                                                }}/>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <Modal show={createAccountDialog} onHide={closeCreateAccountDialog} backdrop="static">
                <ModalHeader closeButton style={{backgroundColor: "#506fd9", color: "white"}}>
                    Thêm mới tài khoản
                </ModalHeader>
                <ModalBody>
                    <Container fluid="md">
                        <Row style={{marginBottom: "10px"}}>
                            <Col md={4}>
                                Tên đăng nhập:
                            </Col>
                            <Col md={7}>
                                <FormControl type="text" onChange={e => setNewUsername(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "10px"}}>
                            <Col md={4}>
                                Thư điện tử:
                            </Col>
                            <Col md={7}>
                                <FormControl type="email" onChange={e => setNewEmail(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "10px"}}>
                            <Col md={4}>
                                Họ tên:
                            </Col>
                            <Col md={7}>
                                <FormControl type="email" onChange={e => setNewFullName(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "10px"}}>
                            <Col md={4}>
                                Mật khẩu:
                            </Col>
                            <Col md={7}>
                                <FormControl type="password" onChange={e => setNewPassword(e.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={closeCreateAccountDialog}>Hủy</Button>
                    <Button variant="success" onClick={createAccount}>Tạo mới</Button>
                </ModalFooter>
            </Modal>
            <Modal show={resetPasswordDialog} onHide={closeResetPasswordDialog} backdrop="static">
                <ModalHeader closeButton style={{backgroundColor: "#506fd9", color: "white"}}></ModalHeader>
                <ModalBody>
                    <Container fluid="md">
                        <Row>
                            <Col md={4}>
                                Mật khẩu mới:
                            </Col>
                            <Col md={7}>
                                <FormControl type="password" onChange={e => setResetPassword(e.target.value)} />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={closeResetPasswordDialog}>Hủy</Button>
                    <Button variant="success" onClick={updatePassword}>Đồng ý</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}