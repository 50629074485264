import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, FormControl, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import { listSocialRequest, updateSocialRequest } from "../services/socialService";
import Notification from "../components/Notification"

export default function SocialManagement(){
    const [facebookLink, setFaceBookLink] = useState("");
    const [facebookActive, setFacebookActive] = useState(true);
    const [youtubeLink, setYoutubeLink] = useState("");
    const [youtubeActive, setYoutubeActive] = useState(true);
    const [isInit, setIsInit] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");

    const loadSocials = async() => {
        const data = await listSocialRequest();
        if(data.isError){
            displayNotification("Can not load socials", "danger");
            return;
        }
        for(let i = 0; i < data.items.length; i++){
            if(data.items[i].type === "facebook"){
                setFaceBookLink(data.items[i].socialLink);
                setFacebookActive(data.items[i].active)
            }else if(data.items[i].type === "youtube"){
                setYoutubeLink(data.items[i].socialLink);
                setYoutubeActive(data.items[i].active)
            }
        }
    }

    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }

    const updateSocials = async() => {
        let data = null;
        data = await updateSocialRequest(facebookLink, "facebook", facebookActive);
        if(data.isError){
            displayNotification("Can not update Facebook", "danger");
        }else{
            displayNotification("Update Facebook successfull", "success");
        }
        data = await updateSocialRequest(youtubeLink, "youtube", youtubeActive);
        if(data.isError){
            displayNotification("Can not update Youtube", "danger");
        }else{
            displayNotification("Update Youtube successfull", "success");
        }
    }

    useEffect(() => {
        if(!isInit){
            loadSocials()
        }        
    }, [isInit])
    if(!isInit){
        setIsInit(true);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Facebook Link:
                        </Col>
                        <Col md={4}>
                            <FormControl value={facebookLink} type="text" onChange={e => setFaceBookLink(e.target.value)} />
                        </Col>
                        <Col md={2}>
                            <FormCheck checked={facebookActive} type="checkbox" label="active" onChange={e => setFacebookActive(e.target.checked)}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Youtube Link:
                        </Col>
                        <Col md={4}>
                            <FormControl value={youtubeLink} type="text" onChange={e => setYoutubeLink(e.target.value)}/>
                        </Col>
                        <Col md={2}>
                            <FormCheck checked={youtubeActive} type="checkbox" label="active" onChange={e => setYoutubeActive(e.target.checked)}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={6} style={{textAlign: "right"}}>
                            <Button variant="primary" onClick={updateSocials}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </React.Fragment>
    )
}