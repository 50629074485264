import React, { useState } from "react";
import { Button, Col, Container, FormCheck, FormControl, FormSelect, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tab, Table, Tabs } from "react-bootstrap";
import Header from "../layouts/Header";
import Notification from "../components/Notification"
import { createNewCategoryRequest, getCategoryRequest, listCategoryRequest, updateCategoryRequest } from "../services/categoryService";
import PaginationComp from "../components/PaginationComp";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

export default function CategoryeManagement(){
    const [createNewDialog, setCreateNewDialog] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [enCategoryName, setEnCategoryName] = useState("")
    const [categoryDesc, setCategoryDesc] = useState("");
    const [enCategoryDesc, setEnCategoryDesc] = useState("")
    const [shouldPublish, setShouldPublish] = useState("true");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categoryLink, setCategoryLink] = useState("");
    const [categoryQuote, setCategoryQuote] = useState("");
    const [enCategoryQuote, setEnCategoryQuote] = useState("");

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");

    const [searchStr, setSearchStr] = useState("");
    const [published, setPublished] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [categories, setCategories] = useState([]);
    const [totalItem, setTotalItem] = useState(0);

    const [categoryAction, setCategoryAction] = useState("new");



    const closeCreateNewDialog = async() => {
        setCategoryName("");
        setCategoryDesc("");
        setShouldPublish("");
        setSelectedCategory("");
        setCategoryLink("");
        setCategoryQuote("");
        await listCategory()
        setCreateNewDialog(false);
    }
    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }
    const createNewCategory = async() => {
        if(categoryName === "" || categoryDesc === ""){
            displayNotification("Name or Description is empty", "danger");
            return;
        }
        const data = await createNewCategoryRequest(categoryName, categoryDesc, shouldPublish, categoryLink, categoryQuote);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCreateNewDialog();
    }
    const listCategory = async() => {
        const data = await listCategoryRequest(searchStr, published, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTotalItem(data.totalItem);
        setCategories(data.items);
    }
    const onSelectStatus = (event) => {
        setPublished(event.target.value)
    }
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }

    const getCategory = async(id) => {
        const data = await getCategoryRequest(id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setCategoryName(data.name);
        setShouldPublish(data.published ? "true" : "false");
        setCategoryDesc(data.description);
        setCategoryLink(data.link);
        setCategoryQuote(data.quote);
        setEnCategoryName(data.en_name);
        setEnCategoryDesc(data.en_description)
        setEnCategoryQuote(data.en_quote)
    }
    const editCategory = async() => {
        const data = await updateCategoryRequest(selectedCategory, categoryName, categoryDesc, shouldPublish, categoryLink, 
            categoryQuote, enCategoryName, enCategoryDesc, enCategoryQuote);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCreateNewDialog();
    }
    useEffect(() => {
        listCategory()
    }, [pageIndex]);
    return (
        <>
            <React.Fragment>
                <Header />
                <div className="main main-app p-3 p-lg-4">
                    <Container fluid>
                        <Row>
                            <Col md={2}>
                                Category's title:
                            </Col>
                            <Col md={4}>
                                <FormControl type="text" onChange={e => setSearchStr(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col md={2}>
                                Category's status:
                            </Col>
                            <Col md={4} style={{verticalAlign: "center"}}>
                                <FormSelect onChange={onSelectStatus}>
                                    <option value="">All</option>
                                    <option value={true}>Published</option>
                                    <option value={false}>Unpublished</option>
                                </FormSelect>
                            </Col>
                            <Col md={2}>
                                <Button variant="primary" onClick={ e => {
                                    if(pageIndex !== 0){
                                        setPageIndex(0);
                                    }else{
                                        listCategory();
                                    }
                                }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                Category list
                            </Col>
                            <Col md={8}></Col>
                            <Col md={2} style={{textAlign: "right"}}>
                                <Button variant="success" onClick={e => {
                                        setCreateNewDialog(true)
                                        setCategoryAction("new");
                                    }
                                } style={{display: "none"}}>Add new</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col md={12}>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                            <th style={{textAlign: "center"}}>Edit</th>
                                            {/* <th style={{textAlign: "center"}}>Publish</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories && 
                                            <>
                                                {categories.map((category) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {category.name}
                                                            </td>
                                                            <td>
                                                                {category.published ? "published": "unpublished"}
                                                            </td>
                                                            <td>
                                                                {(DateTime.fromISO(category.createdAt)).toISODate(DateTime.DATE_SHORT)}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faEdit} onClick={e => {
                                                                    getCategory(category.id);
                                                                    setCategoryAction("edit");
                                                                    setSelectedCategory(category.id);
                                                                    setCreateNewDialog(true);
                                                                }}/>
                                                            </td>
                                                            {/* <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faShareFromSquare} />
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}></Col>
                            <Col md={4}>
                                <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal show={createNewDialog} onHide={closeCreateNewDialog} size="lg" backdrop>
                    <ModalHeader closeButton>Create new category</ModalHeader>
                    <ModalBody>
                        <Tabs defaultActiveKey="vi" fill justify>
                            <Tab eventKey="vi" title="Tiếng Việt">
                                <Container>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Category name: 
                                        </Col>
                                        <Col md={4}>
                                            <FormControl type="text" onChange={e => setCategoryName(e.target.value)} value={categoryName}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Category Link:
                                        </Col>
                                        <Col md={4}>
                                            <FormControl type="text" onChange={e => setCategoryLink(e.target.value)} value={categoryLink} onKeyDown={e => {
                                                switch(e.keyCode){
                                                    case 8:
                                                    case 9:
                                                    case 13:
                                                    case 37:
                                                    case 38:
                                                    case 39:
                                                    case 40:
                                                        break;
                                                    default:
                                                        let regex = new RegExp("^[a-zA-Z0-9\-]+$");
                                                        let key = e.key;
                                                        if(!regex.test(key)){
                                                            e.preventDefault()
                                                            return false;
                                                        }
                                                        break;
                                                }
                                            }} readOnly/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Publish:
                                        </Col>
                                        <Col md={3}>
                                            <FormSelect value={shouldPublish} onChange={e => setShouldPublish(e.target.value)}>
                                                <option value={true}>Published</option>
                                                <option value={false}>Unpublished</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Category description: 
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={12}>
                                            <FormControl value= {categoryDesc} type="text" as="textarea" onChange={e => setCategoryDesc(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Category Quote: 
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={12}>
                                            <FormControl value= {categoryQuote} type="text" as="textarea" onChange={e => setCategoryQuote(e.target.value)}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="en" title="English">
                                <Container>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={3}>
                                                Category name: 
                                            </Col>
                                            <Col md={4}>
                                                <FormControl type="text" onChange={e => setEnCategoryName(e.target.value)} value={enCategoryName}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={3}>
                                                Category Link:
                                            </Col>
                                            <Col md={4}>
                                                <FormControl type="text" onChange={e => setCategoryLink(e.target.value)} value={categoryLink} onKeyDown={e => {
                                                    switch(e.keyCode){
                                                        case 8:
                                                        case 9:
                                                        case 13:
                                                        case 37:
                                                        case 38:
                                                        case 39:
                                                        case 40:
                                                            break;
                                                        default:
                                                            let regex = new RegExp("^[a-zA-Z0-9\-]+$");
                                                            let key = e.key;
                                                            if(!regex.test(key)){
                                                                e.preventDefault()
                                                                return false;
                                                            }
                                                            break;
                                                    }
                                                }} readOnly/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={3}>
                                                Publish:
                                            </Col>
                                            <Col md={3}>
                                                <FormSelect value={shouldPublish} onChange={e => setShouldPublish(e.target.value)}>
                                                    <option value={true}>Published</option>
                                                    <option value={false}>Unpublished</option>
                                                </FormSelect>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={3}>
                                                Category description: 
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={12}>
                                                <FormControl value= {enCategoryDesc} type="text" as="textarea" onChange={e => setEnCategoryDesc(e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={3}>
                                                Category Quote: 
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: "15px"}}>
                                            <Col md={12}>
                                                <FormControl value= {enCategoryQuote} type="text" as="textarea" onChange={e => setEnCategoryQuote(e.target.value)}/>
                                            </Col>
                                        </Row>
                                    </Container>
                            </Tab>
                        </Tabs>                        
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="danger" onClick={closeCreateNewDialog}>Cancel</Button>
                        <Button variant="primary" onClick={e => {
                            if(categoryAction === "new"){
                                createNewCategory()
                            }else{
                                editCategory();
                            }
                        }}>Save</Button>
                    </ModalFooter>
                </Modal>
                <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            </React.Fragment>
        </>
    );
}