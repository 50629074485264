import axios from 'axios';

export const sendPostRequest = async(requestType, uri, data, isPublic) => {
    const config = {
        method: requestType,
        url: process.env.REACT_APP_BE_DOMAIN + uri,
        headers: {
            'Content-Type': "application/json",
            'Authorization': sessionStorage.getItem("token")
        },
        data:  JSON.stringify(data)
    }
    try{;
        const response = await axios(config); 
        return response.data.data;
    }catch(error){
        return {
            isError: true,
            msg: error.response.data.message
        }
    }
}

export const sendGetRequest = async(uri, request) => {
    const config = {
        method: "get",
        url: process.env.REACT_APP_BE_DOMAIN + uri,
        headers: {
            'Content-Type': "application/json",
            'Authorization': sessionStorage.getItem("token")
        },
        params:  request
    }
    try{;
        const response = await axios(config);  
        return response.data.data;
    }catch(error){    
        return {
            isError: true,
            msg: error.message
        }
    }
}

export const uploadFileRequest = async(file) => {
    const formData = new FormData();
    formData.append(
        "myFile",
        file,
        file.name
    );
    const config = {
        method: 'post',
        url: process.env.REACT_APP_BE_DOMAIN + "/api/file/upload",
        headers: {
          'Authorization': localStorage.getItem("accessToken")
        }
      }
    try{
        const resp = await axios.postForm(process.env.REACT_APP_BE_DOMAIN + "/api/file/upload", formData, config);        
        return resp.data;
    }catch(error){    
        return {
            isError: true,
            msg: error.response.data.message
        }
    }
}

export const fileUploadsRequest = async (files) => {
    const formData = new FormData();
    for(let i = 0; i < files.length; i++){
      formData.append(
          "myFile",
          files[i],
          files[i].name
      );
    }
    
    const config = {
        method: 'post',
        url: process.env.REACT_APP_BE_DOMAIN + "/api/file/uploads",
        headers: {
          'Authorization': localStorage.getItem("accessToken")
        }
      }
    try{
        const resp = await axios.postForm(process.env.REACT_APP_BE_DOMAIN + "/api/file/uploads", formData, config);        
        return resp.data;
    }catch(error){    
        return {
            isError: true,
            msg: error.response.data.message
        }
    }    
  
  }