import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, FormControl, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import { listSeoRequest, updateSeoRequest } from "../services/seoService";
import Notification from "../components/Notification"

export default function SeoManagement(){
    const [seo, setSeo] = useState("");
    const [seoActive, setSeoActive] = useState(false);
    const [seoId, setSeoId] = useState("");

    const [isInit, setIsInit] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");


    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }

    const listSeo = async() => {
        const data = await listSeoRequest();
        if(data.isError){
            displayNotification("Can not list seo", "danger");
            return;
        }
        for(let i = 0; i < data.items.length; i++){
            if(data.items[i].type === "google"){
                setSeo(data.items[i].seoId)
                setSeoActive(data.items[i].active)
                setSeoId(data.items[i].id)
            }
        }
    }
    const updateSeo = async() => {
        const data = await updateSeoRequest(seoId, seo, "google", seoActive);
        if(data.isError){
            displayNotification("Can not update seo", "danger");
            return;
        }else{
            displayNotification("Update seo successull", "success");
        }
    }
    useEffect(() => {
        if(isInit){
            listSeo()
        }        
    }, [isInit]);
    if(!isInit){
        setIsInit(true);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Google Tag ID:
                        </Col>
                        <Col md={4}>
                            <FormControl value={seo} type="text" onChange={e => setSeo(e.target.value)}/>
                        </Col>
                        <Col md={2}>
                            <FormCheck lable="active" checked={seoActive} onChange={e => setSeoActive(e.target.checked)} />
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={6} style={{textAlign: "right"}}>
                            <Button variant="primary" onClick={updateSeo}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </React.Fragment>
    )
}