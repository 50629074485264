
const userMenu = [
  {
    "label": "User",
    "link": "/user",
    "icon": "ri-user-line"
  }
];

const contentMenu = [
  // {
  //   label: "Home page",
  //   link: "/homepage",
  //   icon: "ri-home-8-line"
  // },
  {
    "label": "Category",
    "link": "/categories",
    "icon": "ri-book-2-line"
  },
  {
    "label": "Content",
    "link": "/contents",
    "icon": "ri-pages-line"
  },
  {
    label: "Gallery",
    link: "/gallery",
    icon: "ri-image-2-line"
  },
  {
    label: "About us",
    link: "/about-us",
    icon: "ri-question-line"
  }
]

const contactMenu = [
  {
    "label": "Contact",
    "link": "/contacts",
    icon: "ri-account-box-line"
  }
]

const configurationMenu = [
  {
    label: "Social",
    link: "/social",
    icon: "ri-rss-line"
  },
  {
    label: "SEO",
    link: "/seo",
    icon: "ri-ie-line"
  }
]



export { userMenu, contentMenu, contactMenu, configurationMenu};