import { sendGetRequest, sendPostRequest } from "./service"

export const saveFileInfoRequest = async(name, type, path) => {
    let params = {
        fileName: name,
        fileType: type,
        filePath: path
    }
    return await sendPostRequest("post", "/api/private/file", params);
}
export const saveFileInfosRequest = async(files) => {
    return await sendPostRequest("post", "/api/private/files", {files: files});
}