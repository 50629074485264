import { sendGetRequest, sendPostRequest } from "./service"

export const createNewCategoryRequest = async(catName, catDesc, isPublished, catLink, quote, enName, enDesc, enQuote) => {
    let params = {
        name: catName,
        description: catDesc,
        link: catLink,
        published: isPublished === "true" ? true : false,
        quote: quote,
        enName: enName,
        enDescription: enDesc,
        enQuote: enQuote
    }

    return await sendPostRequest("post", "/api/private/category", params);
}
export const listCategoryRequest = async(searchStr, isPublished, pageIndex, pageSize) => {
    let params = {
        pageIndex: pageIndex,
        pageSize: pageSize
    };
    if(searchStr){
        params.searchKey = searchStr
    }
    if(isPublished !== null && isPublished !== "all"){
        params.isPublished = isPublished === "true" ? true : false;
    }
    return await sendGetRequest("/api/private/category", params);
}

export const getCategoryRequest = async(id) => {
    return await sendGetRequest("/api/private/category/detail", {id: id})
}

export const updateCategoryRequest = async(id, name, description, published, link, quote, enName, enDescription, enQuote) => {
    let params = {
        categoryId: id,
        name: name,
        description: description,
        published: published === "true" ? true : false,
        link: link,
        quote: quote,
        enName: enName,
        enDescription: enDescription,
        enQuote: enQuote
    }
    return await sendPostRequest("put", "/api/private/category", params);
}