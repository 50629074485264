import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useState } from 'react';
import usetoken from "../components/useToken"
import { useEffect } from "react";
import useUserProfile from "../components/useUserProfile";

export default function Main() {
  const {token, setToken} = usetoken();
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;
  const navigate = useNavigate();
  const {profile} = useUserProfile();


  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");
  //check if user loged in
  useEffect(() => {    
    if(!token){
      navigate("/auth/pages/signin");
    }
  }, [token]);  
  // scroll to top when switching pages
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Sidebar profile={profile}/>
      <Outlet />
    </React.Fragment>
  )
}