import { Button, Col, Container, FormControl, FormSelect, Image, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "react-bootstrap";
import Header from "../layouts/Header";
import React, { useState } from "react";
import Notification from "../components/Notification"
import PaginationComp from "../components/PaginationComp";
import { createGalleryRequest, deleteGaleryPhotoRequest, getGalleryPhotoRequest, getGalleryRequest, listGalleryRequest, updateGalleryPhotoRequest, updateGalleryRequest } from "../services/galleryService";
import { useEffect } from "react";
import { fileUploadsRequest } from "../services/service";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { saveFileInfosRequest } from "../services/fileService";
import "../scss/pages/_gallery.scss"
import {RingLoader } from "react-spinners";

export default function Gallery(){
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [loading, setLoading] = useState(false);

    const [galleries, setGalleries] = useState([]);
    const [key, setKey] = useState("");
    const [published, setPublished] = useState("all");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalItem, setTotalItem] = useState(0);

    const [editDialog, setEditDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [shouldPublish, setShouldPublish] = useState("true");
    const [description, setDescription] = useState("");
    const [action, setAction] = useState("new");
    const [enTitle, setEnTitle] = useState("");
    const [enDescription, setEnDescription] = useState("")
    

    const [showPhotos, setShowPhotos] = useState(false);
    const [photos, setPhotos] = useState([]);//list of photo's id
    // const [uploadFiles, setUploadFiles] = useState([]);
    const [selectedGalleryId, setSelectedGalleryId] = useState("");
    const [photoIndex, setPhotoIndex] = useState(0);
    const [photoSize, setPhotoSize] = useState(20);

    const loadGalleryPhoto = async(id) => {
        setLoading(true)
        const data = await getGalleryPhotoRequest(id, photoIndex, photoSize);
        if(data.isError){
            displayNotification("Can not get gallery photo", "danger");
            return;
        }
        setSelectedGalleryId(id);
        let items = [];
        for(let i = 0; i < data.items.length; i++){
            items.push({
                galleryId: data.items[i].gallery_id,
                path: data.items[i].File?.file_path,
                id: data.items[i].id
            })
        }
        setPhotos(items);
        setLoading(false)
    }

    const closePhotoDialog = () => {
        setShowPhotos(false);
    }
    

    const closeEditDialog = async() =>{
        setTitle("");
        setDescription("");
        setShouldPublish("true");
        setEditDialog(false);
        setAction("new");
        setEnTitle("")
        setEnDescription("")
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await listGallery()
        }
    }


    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
        setLoading(false)
    }
    const onSelectStatus = (event) => {
        setPublished(event.target.value)
    }
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }    
    const listGallery = async() => {
        setLoading(true)
        const data = await listGalleryRequest(key, published, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTotalItem(data.total);
        setGalleries(data.items);
        setLoading(false)
    }
    useEffect(() => {
        listGallery()
    }, [pageIndex])
    const onFileChange = async(event) => {
        setLoading(true)
        // setUploadFiles(event.target.files);
        let resp = await fileUploadsRequest(event.target.files);
        if(resp.isError){
            displayNotification(resp.msg, "danger");
            return;
        }
        //save file to database
        let files = resp;
        resp = await saveFileInfosRequest(files);
        if(resp.isError){
            displayNotification("Can not save files", "danger");
            return;
        }
        let ids = []
        for(let i = 0; i < resp.items.length; i++){
            ids.push(resp.items[i].id);
        }
        //save to the gallery
        resp = await updateGalleryPhotoRequest(selectedGalleryId, ids);
        if(resp.isError){
            displayNotification("Can not add photos to gallery", "danger");
            return;
        }
        setLoading(false)
        await loadGalleryPhoto(selectedGalleryId);
    }
    const createOrEditGallery = async() => {
        setLoading(true)
        let data = null
        if(action === "new"){
            data = await createGalleryRequest(title, description, shouldPublish, enTitle, enDescription);
            if(data.isError){
                displayNotification(data.msg, "danger");
                return;
            }
        }else if(action === "edit"){
            data = await updateGalleryRequest(selectedGalleryId, title, description, shouldPublish, enTitle, enDescription);
            if(data.isError){
                displayNotification(data.msg, "danger");
                return;
            }
        }
        closeEditDialog();
        setLoading(false)
    }
    const getGallery = async(id) => {
        setLoading(true)
        const data = await getGalleryRequest(id)
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTitle(data.title);
        setDescription(data.short_description);
        setShouldPublish(data.published ? "true" : "false");
        setSelectedGalleryId(data.id);
        setEnTitle(data.en_title)
        setEnDescription(data.en_short_description);
        setAction("edit");
        setEditDialog(true);
        setLoading(false)
    }
    const deletePhoto = async(id) => {
        setLoading(true)
        let ids = [id]
        const data = await deleteGaleryPhotoRequest(ids);
        if(data.isError){
            displayNotification("Can not delete photo", "danger");
            return;
        }
        await loadGalleryPhoto(selectedGalleryId)
        setLoading(false)
    }
    return(
        <>
            <React.Fragment>
                <Header />
                <div className="main main-app p-3 p-lg-4">
                    <Container fluid>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Gallery name:
                            </Col>
                            <Col md={4}>
                                <FormControl type="text" onChange={e => setKey(e.target.value)}/>
                            </Col>                            
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Gallery status:
                            </Col>
                            <Col md={2}>
                                <FormSelect onChange={onSelectStatus}>
                                    <option value="all">All</option>
                                    <option value="true">Published</option>
                                    <option value="false">Unpublished</option>
                                </FormSelect>
                            </Col>
                            <Col md={2}>
                                <Button variant="primary" onClick={e => {
                                    if(pageIndex !== 0){
                                        setPageIndex(0)
                                    }else{
                                        listGallery()
                                    }
                                }}>Search</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={12} style={{textAlign: "right"}}>
                                <Button variant="success" onClick={e => {
                                    setEditDialog(true);
                                    setAction("new");
                                }}>Add new</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={12}>
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <th>Gallery name</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                            <th style={{textAlign: "center"}}>Edit</th>
                                            <th style={{textAlign: "center"}}>Photo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {galleries &&
                                            <>
                                                {galleries.map((gallery) => {
                                                    return (
                                                        <tr>
                                                            <td>{gallery.title}</td>
                                                            <td>{gallery.published ? "published" : "unpublished"}</td>
                                                            <td>{(DateTime.fromISO(gallery.createdAt)).toISODate(DateTime.DATE_SHORT)}</td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faEdit} size="lg" onClick={e => getGallery(gallery.id)} />
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faPhotoFilm} size="lg" onClick={e => {
                                                                    loadGalleryPhoto(gallery.id);
                                                                    setShowPhotos(true)
                                                                    }}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}></Col>
                            <Col md={4}>
                                <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <Modal show={editDialog} onHide={closeEditDialog} size="xl">
                <ModalHeader closeButton>
                    Gallery content
                </ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Gallery name (Vietnamese):
                            </Col>
                            <Col md={3}>
                                <FormControl value={title} type="text" onChange={e => setTitle(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Gallery name (English):
                            </Col>
                            <Col md={3}>
                                <FormControl value={enTitle} type="text" onChange={e => setEnTitle(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Status:
                            </Col>
                            <Col md={2}>
                                <FormSelect value={shouldPublish} onChange={e => setShouldPublish(e.target.value)}>
                                    <option value="true">Published</option>
                                    <option value="false">Unpublished</option>
                                </FormSelect>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Description (Vietnamese):
                            </Col>
                            <Col md={6}>
                                <FormControl value={description} type="text" as="textarea" onChange={e => setDescription(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                Description (English):
                            </Col>
                            <Col md={6}>
                                <FormControl value={enDescription} type="text" as="textarea" onChange={e => setEnDescription(e.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={createOrEditGallery}>Save</Button>
                    <Button variant="secondary" onClick={closeEditDialog}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal show={showPhotos} onHide={closePhotoDialog} size="xl" backdrop="static">
                <ModalHeader closeButton>Photo gallery</ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Choose photos to upload:
                            </Col>
                            <Col md={6}>
                                <FormControl type="file" multiple accept=".jpg, .jpeg, .png" onChange={onFileChange} />
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Photo list:
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={12} style={{backgroundColor: "gray"}}>
                                {photos && 
                                    <>
                                        {photos.map((photo) => {
                                            return (
                                                <div className="img-wrap">
                                                    <span className="close" onClick={e => deletePhoto(photo.id)}>&times;</span>
                                                    <Image width={200} src={process.env.REACT_APP_BE_DOMAIN + "/images/" + photo.path} thumbnail style={{margin: "5px"}}/>
                                                </div>
                                            );
                                        })}
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={12} style={{textAlign: "right"}}>
                                <Button variant="secondary" onClick={closePhotoDialog}>Close</Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 9999
            }}>
                <RingLoader 
                    loading={loading}
                    color="green"
                />
            </div>
        </>
    )
}