import React, { useState } from "react";
import Header from "../layouts/Header";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { getCategoryRequest, listCategoryRequest } from "../services/categoryService";
import Notification from "../components/Notification"
import { useEffect } from "react";
import { createNewContentRequest, updateContentRequest } from "../services/contentService";

 export default function AboutUs(){
    const [itemId, setItemId] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [content, setContent] = useState("");
    const [enContent, setEnContent] = useState("");
    const [initData, setInitData] = useState(false);
    const [aboutUsId, setAboutUsId] = useState("");
    const [createOrUpdate, setCreateOrUpdate] = useState("create");

    const Quill = ReactQuill.Quill;
    let Font = Quill.import("formats/font");
    Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
    Quill.register(Font, true);


    const listCategories = async() => {
        const data = await listCategoryRequest(null, "true", 0, 30);
        if(data.isError){
            displayNotification("Can not list categories", "danger");
            return;
        }
        for(let i = 0; i < data.items.length; i++){
            if(data.items[i].link === "about-us"){
                setAboutUsId(data.items[i].id)
            }
        }
    }
    const getCategory = async() => {
        const data = await getCategoryRequest(aboutUsId);
        
        if(data.isError){
            displayNotification("Can not get category", "danger");
            return;
        }
        if(data.CategoryContents){
            setItemId(data.CategoryContents[0].id);
            setContent(data.CategoryContents[0].content);
            setEnContent(data.CategoryContents[0].en_content)
            setCreateOrUpdate("update")
        }else{
            setCreateOrUpdate("create");
        }
    }
    useEffect(() => {
        if(aboutUsId !== ""){
            getCategory();
        }
    }, [aboutUsId])
    if(!initData){
        listCategories();
        setInitData(true);
    }

    const editorModules = {
        toolbar: [
            [
                {
                    header: [1, 2, false],
                    
                },
                { 'font': Font.whitelist }
            ],
            ["bold", "italic", "underline", "strike"],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
        ]
    }
    const editorFormats = [
        "font",
        "header",
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }
    const createOrUpdateItem = async() => {
        let data = null;
        if(createOrUpdate === "create"){
            data = await createNewContentRequest("Tổng quan về Paradise Sky", content, "", aboutUsId, "true", null, null, enContent, null);
            if(data.isError){
                displayNotification("Can not create content", "danger");
                return;
            }else{
                setCreateOrUpdate("update");
                setItemId(data.id);
            }
        }else{
            data = await updateContentRequest(itemId, "Tổng quan về Paradise Sky", "", content, "true", aboutUsId, null, null, null, null, enContent);
            if(data.isError){
                displayNotification("Can not update content", "danger");
                return;
            }
        }
    }
    return (
        <>
            <React.Fragment>
                <Header />
                <div className="main main-app p-3 p-lg-4">
                    <Tabs fill justify defaultActiveKey="vi">
                        <Tab eventKey="vi" title="Tiếng Việt">
                            <Container fluid>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <ReactQuill theme="snow" 
                                            modules={editorModules}
                                            formats={editorFormats}
                                            onChange={content => setContent(content)}
                                            value={content}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "15px"}}>
                                <Col md={2}></Col>
                                    <Col md={8} style={{textAlign: "right"}}>
                                        <Button variant="primary" onClick={createOrUpdateItem}>Save</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="en" title="English">
                            <Container fluid>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <ReactQuill theme="snow" 
                                            modules={editorModules}
                                            formats={editorFormats}
                                            onChange={content => setEnContent(content)}
                                            value={enContent}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "15px"}}>
                                <Col md={2}></Col>
                                    <Col md={8} style={{textAlign: "right"}}>
                                        <Button variant="primary" onClick={createOrUpdateItem}>Save</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                    
                </div>
            </React.Fragment>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </>
    )
 }