import React from "react";
import Header from "../layouts/Header";
import { Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { useState } from "react";
import { changePasswordRequest, getUserDetailRequest } from "../services/userService";
import Notification from "../components/Notification"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function UserProfile(){
    const [user, setUser] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [isInit, setIsInit] = useState(false);

    const [changePasswordDialog, setChangePasswordDialog] = useState(false);
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")

    const navigate = useNavigate()

    const loadProfile = async() => {
        const data = await getUserDetailRequest();
        if(data.isError){
            displayNotification(data.msg, "danger")
            return;
        }
        setUser(data)
    }

    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }
    useEffect(() => {
        if(isInit){
            loadProfile()
        }
    }, [isInit])
    const closeChangePasswordDialog = () => {
        setChangePasswordDialog(false);
        setOldPassword("");
        setNewPassword("");
    }
    const changePassword = async() => {
        if(newPassword === ""){
            displayNotification("Password can not be empty", "danger");
            return;
        }
        const data = await changePasswordRequest(oldPassword, newPassword);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        navigate("/auth/pages/signin");
    }
    if(!isInit){
        setIsInit(true);
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>
                            Username:
                        </Col>
                        <Col md={3}>
                            {user.username}
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>
                            Email:
                        </Col>
                        <Col md={3}>
                            {user.email}
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>
                            Fullname:
                        </Col>
                        <Col md={3}>
                            {user.fullName}
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>
                            Password:
                        </Col>
                        <Col md={3}>
                            <FormControl type="password" value="123456" />
                        </Col>
                        <Col md={2}>
                            <Button variant="danger" onClick={e => setChangePasswordDialog(true)}>Change password</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <Modal show={changePasswordDialog} onHide={closeChangePasswordDialog} backdrop="static">
                <ModalHeader closeButton style={{backgroundColor: "#dc3545"}}></ModalHeader>
                <ModalBody>
                    <Container fluid="md">
                        <Row style={{marginTop: "10px"}}>
                            <Col md={4}>
                                Old password: 
                            </Col>
                            <Col md={6}>
                                <FormControl type="password" size="sm" onChange={e => setOldPassword(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={4}>
                                New password: 
                            </Col>
                            <Col md={6}>
                                <FormControl type="password" size="sm" onChange={e => setNewPassword(e.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={changePassword}>Change</Button>
                    <Button variant="secondary" onClick={closeChangePasswordDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}