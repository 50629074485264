import { sendGetRequest } from "./service";

export const listContactRequest = async(email, phone, fromDate, toDate, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size
    }
    if(fromDate){
        params.fromDate = fromDate
    }
    if(toDate){
        params.toDate = toDate;
    }
    if(email){
        params.email = email
    }
    if(phone){
        params.phoneNumber = phone
    }
    return await sendGetRequest("/api/private/contact", params);
}