import { sendGetRequest, sendPostRequest } from "./service"

export const createSocialRequest = async(type, socialLink, active) => {
    let params = {
        type: type,
        socialLink: socialLink,
        active: active
    }
    return await sendPostRequest("post", "/api/private/social", params);
}

export const listSocialRequest = async() => {
    return await sendGetRequest("/api/private/social", {
        pageIndex: 0,
        pageSize: 20
    })
}

export const updateSocialRequest = async(socialLink, type, active) => {
    let params = {
        socialLink: socialLink,
        type: type,
        active: active
    }
    return await sendPostRequest("put", "/api/private/social", params);
}