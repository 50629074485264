
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";

const Notification = ({showToast, toastContent, toastVariant, closeNotification}) => {
    if(Array.isArray(toastContent)){
        return (
        <>
            <ToastContainer position="middle-center">
                {toastContent.map((item) => 
                    <>
                        <Toast delay={3000} show={showToast} 
                            bg={toastVariant}
                            onClose = {() => closeNotification()} autohide key={Date.now}>                                
                            <Toast.Body className={toastVariant}>
                                {item}
                            </Toast.Body>
                        </Toast>
                    </>
                )}                
            </ToastContainer>
        </>
    );
    }else{
        return (
            <>
                <ToastContainer position="top-end">
                    <Toast delay={3000} show={showToast} 
                        bg={toastVariant}
                        onClose = {() => closeNotification()} autohide>
                        <Toast.Body className={toastVariant}>
                            {toastContent}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </>
        );
    }
    
}

export default Notification;