import { useState } from "react";
import ReactPaginate from "react-paginate";
import "./PaginationComp.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

function Items({ currentItems }) {
    return (
      <>
      </>
    );
  }

const PaginationComp = ({pageSize, total, callback}) => {
    let numPage = Math.ceil(total / pageSize);
    const handlePageClick = async (event) => {
        const newOffset = (event.selected * pageSize) % total;
        await callback(pageSize, newOffset);
    };
    return (
        <>
            <ReactPaginate 
                breakLabel="..."
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={numPage}
                previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                renderOnZeroPageCount={null}
                activeClassName="item pg-active"
                breakClassName="item break-me"
                containerClassName="pagination"
                disabledClassName="disabled-page"
                nextClassName="item next"
                pageClassName="item pagination-page"
                previousClassName="item previous"                
            />
        </>
    );
}

export default PaginationComp;