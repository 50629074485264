import React from "react";
import UserManagement from "../User/UserManagement";
import CategoryeManagement from "../Content/CategoryManagement";
import ContentManagement from "../Content/ContentManagement";
import ContactManagemenet from "../Contact/ContactManagement";
import HomePageManagement from "../Content/HomePageMangement";
import AboutUs from "../Content/AboutUs";
import Gallery from "../Content/Gallery";
import SocialManagement from "../Configuration/SocialManagement";
import SeoManagement from "../Configuration/SeoManagement";
import UserProfile from "../User/UserProfile";

const protectedRoutes = [
  {path: "user", element: <UserManagement />},
  {path: "categories", element: <CategoryeManagement />},
  {path: "contents", element: <ContentManagement />},
  {path: "contacts", element: <ContactManagemenet />},
  {path: "homepage", element: <HomePageManagement />},
  {path: "about-us", element: <AboutUs />},
  {path: "gallery", element: <Gallery />},
  {path: "social", element: <SocialManagement />},
  {path: "seo", element: <SeoManagement />},
  {path: "profile", element: <UserProfile />}
]

export default protectedRoutes;