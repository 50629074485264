import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { loginRequest } from "../services/userService";
import useToken from "../components/useToken";
import useUserProfile from "../components/useUserProfile"

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {token, setToken} = useToken();
  const {profile, setProfile} = useUserProfile()
  const [isAuthenticated, setIsAuthenticated] = useState(0);
  const navigate = useNavigate();
  const onLoginClick = async() => {
    const data = await loginRequest(email, password);
    if(data.isError){
      setIsAuthenticated(3);
      return;
    }
    setToken(data.token);
    setProfile(data.user);
    navigate("/user");
  }
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">ParadiseSky</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
          {isAuthenticated === 3 &&
            <Card.Text style={{color: "red"}}>Invalid username or password</Card.Text>
          }
        </Card.Header>
        <Card.Body>
          <Form method="get" action="/dashboard/finance">
            <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter your email address" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="mb-4">
              {/* <Form.Label className="d-flex justify-content-between">Password <Link to="">Forgot password?</Link></Form.Label> */}
              <Form.Control type="password" placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <Button variant="primary" className="btn-sign" onClick={onLoginClick}>Sign In</Button>
          </Form>
        </Card.Body>
        {/* <Card.Footer>
          Don't have an account? <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer> */}
      </Card>
    </div>
  )
}