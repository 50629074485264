import { sendGetRequest, sendPostRequest } from "./service";

export const listGalleryRequest = async(key, published, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size
    }
    if(key){
        params.searchKey = key;
    }
    if(published !== "" && published !== "all"){
        params.published = published === "true" ? true: false
    }
    return await sendGetRequest("/api/private/gallery", params);
}
export const createGalleryRequest = async(title, description, isPublished, enTitle, enDescription) => {
    let params = {
        title: title,
        shortDescription: description,
        isPublished: isPublished === "true" ? true : false,
        enTitle: enTitle,
        enShortDescription: enDescription
    }
    return await sendPostRequest("post", "/api/private/gallery", params);
}

export const getGalleryRequest = async(id) => {
    return await sendGetRequest("/api/private/gallery/detail", {id: id});
}
export const updateGalleryRequest = async(id, title, description, published, enTitle, enDescription) => {
    let params = {
        galleryId: id,
        title: title,
        shortDescription: description,
        published: published === "true" ? true : false,
        enTitle: enTitle,
        enShortDescription: enDescription
    }
    return await sendPostRequest("put", "/api/private/gallery", params);
}

export const getGalleryPhotoRequest = async(id, index, size) => {
    let params = {
        galleryId: id,
        pageIndex: index,
        pageSize: size
    }
    return await sendGetRequest("/api/private/gallery/file", params);
}

export const updateGalleryPhotoRequest = async(id, fileIds) => {
    let params = {
        galleryId: id,
        fileIds: fileIds
    }
    return await sendPostRequest("put", "/api/private/gallery/file", params);
}

export const deleteGaleryPhotoRequest = async(ids) => {
    return sendPostRequest("delete", "/api/private/gallery/file", {galleryFileIds: ids});
}