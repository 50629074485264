import React, { useCallback, useRef, useState } from "react";
import { Button, Col, Container, FormCheck, FormControl, FormSelect, Image, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tab, Table, Tabs } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

import Header from "../layouts/Header";
import { listCategoryRequest } from "../services/categoryService";
import Notification from "../components/Notification"
import PaginationComp from "../components/PaginationComp";
import { createNewContentRequest, deleteContentRequest, getContentRequest, listContentRequest, updateContentRequest } from "../services/contentService";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { uploadFileRequest } from "../services/service";
import { saveFileInfoRequest } from "../services/fileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {RingLoader } from "react-spinners";

export default function ContentManagement(){
    const [createNewDialog, setCreateNewDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [enTitle, setEnTitle] = useState("")
    const [categoryId, setCategoryId] = useState("");
    const [content, setContent] = useState("");
    const [enContent, setEnContent] = useState("")
    const [shortDescription, setShortDescription] = useState("");
    const [enShortDescription, setEnShortDescription] = useState("")
    const [shouldPublish, setShouldPublish] = useState("false");
    const [uploadFile, setUploadFile] = useState(null);
    const [image, setImage] = useState("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E")
    const [imageId, setImageId] = useState("");//keep the current uploaded image's id
    const [action, setAction] = useState("new");
    const [selectedItem, setSelectedItem] = useState({});
    const [cloneCategories, setCloneCategories] = useState([]);//we don't allow create new item for about-us page

    const [isInit, setIsInit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalItem, setTotalItem] = useState(0);

    const [search, setSearch] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [published, setPublished] = useState("all");
    const [contents, setContents] = useState([]);

    const [deleteContentDialog, setDeleteContentDialog] = useState(false)
    const [deletedId, setDeletedId] = useState("");

    const Quill = ReactQuill.Quill;
    let Font = Quill.import("formats/font");
    Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
    Quill.register(Font, true);

    const uploadImageRef = useRef(null);
    const quillRef = useRef(null);
    const enQuillRef = useRef(null);
   

    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
        setLoading(false);
    }

    const closeCreateNewDialog = async() => {
        setTitle("");
        setCategoryId("");
        setShortDescription("");
        setContent("");
        setImage("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E")
        setImageId("");
        setAction("new");        
        setCreateNewDialog(false);
        setEnTitle("")
        setEnShortDescription("")
        setEnContent("");
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await searchContent()
        }
    }
    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async() => {
            if(input !== null && input.files !== null){
                const file = input.files[0];  
                // console.log(file);
                const data = await uploadFileRequest(file);
                const url = process.env.REACT_APP_BE_DOMAIN + "/images/" + data.path;
                let quill = quillRef.current;                                
                if(quill){                    
                    // const range = quill.getEditorSelection();     
                    // const range = quill.getEditor().getSelection()                        
                    // range && quill.getEditor().insertEmbed(range.index, "image", url)        
                    let range = quill.getEditor().getSelection();
                    if(range){
                        quill.getEditor().insertEmbed(range.index, "image", url)
                    }else{
                        quill = enQuillRef.current;
                        if(quill){
                            range = quill.getEditor().getSelection();
                            if(range){
                                quill.getEditor().insertEmbed(range.index, "image", url)
                            }
                        }
                    }
                }
            }
        }
    }, [])

    const editorModules = {
        toolbar: {
            container: [
                [
                    {
                        header: [1, 2, false],
                        
                    },
                    { 'font': Font.whitelist }
                ],
                ["bold", "italic", "underline", "strike"],
                [
                    {
                        color: ["red", "blue", "green", "yellow", "black"]
                    }
                ],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
            ],
            handlers: {
                image: imageHandler
            }
        }
    }
    const editorFormats = [
        "font",
        "color",
        "header",
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    const loadCategories = async() =>{
        const data = await listCategoryRequest("", "true", 0, 50);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setCategories(data.items);
        let items = [];
        for(let i = 0; i < data.total; i++){
            if(data.items[i].link !== "about-us"){
                items.push(data.items[i]);
            }
        }
        setCloneCategories(items);
    }
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }
    const createNewContent = async() => {
        if(categoryId === ""){
            displayNotification("You must choose a category", "danger");
            return;
        }
        const data = await createNewContentRequest(title, content, shortDescription, categoryId, 
            shouldPublish, imageId, enTitle, enContent, enShortDescription);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCreateNewDialog();
    }
    const onSelectStatus = (event) => {
        setPublished(event.target.value)
    }
    const searchContent = async() => {
        setLoading(true)
        const data = await listContentRequest(search, searchCategory, published, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTotalItem(data.total);
        setContents(data.items);
        setLoading(false);
    }
    const clickUploadImage = () => {
        uploadImageRef.current.click();
    }
    const onFileChange = async(event) => {
        setLoading(true)
        setUploadFile(event.target.files[0]);
        let resp = await uploadFileRequest(event.target.files[0]);
        if(resp.isError){
            displayNotification(resp.msg, "danger");
            return;
        }
        resp = await saveFileInfoRequest(resp.fileName, resp.mimeType, resp.path);
        if(resp.isError){
            displayNotification("Can not save image", "danger");
            return;
        }
        setImage(process.env.REACT_APP_BE_DOMAIN + "/images/" + resp.file_path);
        setImageId(resp.id);
        setLoading(false);
    }
    const getContent = async(id) => {
        const data = await getContentRequest(id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTitle(data.title);
        setCategoryId(data.category_id);
        setContent(data.content);
        setShortDescription(data.short_description);
        setShouldPublish(data.published);
        if(data.ContentFiles.length > 0){
            setImage(process.env.REACT_APP_BE_DOMAIN + "/images/" + data.ContentFiles[0].File?.file_path);
            setImageId(data.ContentFiles[0].File?.id);
        }
        setEnTitle(data.en_title)
        setEnShortDescription(data.en_short_description)
        setEnContent(data.en_content);
        setSelectedItem(data);
    }
    const updateContent = async() => {
        const data = await updateContentRequest(selectedItem.id, title, shortDescription, content, shouldPublish,
            categoryId, selectedItem.ContentFiles.length > 0 ? selectedItem.ContentFiles[0].id : null, imageId,
            enTitle, enShortDescription, enContent);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCreateNewDialog();
    }
    const closeDeleteContentDialog = () => {
        setDeleteContentDialog(false)
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            searchContent()
        }
    }
    const deleteContent = async() => {
        const data = await deleteContentRequest(deletedId);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeDeleteContentDialog()
    }
    useEffect(() => {
        searchContent()
    }, [pageIndex]);
    if(!isInit){
        loadCategories();
        setIsInit(true);
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Content's title:
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" onChange={e => setSearch(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Category:
                        </Col>
                        <Col md={4}>
                            <FormSelect onChange={e => setSearchCategory(e.target.value)}>
                                <option value="">All categories</option>
                                {categories &&
                                    <>
                                        {categories.map((category) => {
                                            return(
                                                <option value={category.name}>{category.name}</option>
                                            )
                                        })}
                                    </>
                                }
                            </FormSelect>
                        </Col>
                        <Col md={2}>
                            <Button variant="primary" onClick={ e=> {
                                if(pageIndex !== 0){
                                    setPageIndex(0)
                                }else{
                                    searchContent();
                                }
                            }}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Content's status:
                        </Col>
                        <Col md={4} style={{verticalAlign: "center"}}>
                            <FormSelect onChange={onSelectStatus}>
                                <option value="all">All</option>
                                <option value="true">Published</option>
                                <option value="false">Unpublished</option>
                            </FormSelect>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={3}>
                            Total: 
                        </Col>
                        <Col md={7}></Col>
                        <Col md={2} style={{textAlign: "right"}}>
                            <Button variant="success" onClick={e => {
                                setAction("new");
                                setCreateNewDialog(true)}}
                            >Add new</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={15}>
                            <Table bordered>
                                <thead style={{fontWeight: "bold"}}>
                                    <tr>
                                        <th>
                                            Title
                                        </th>
                                        <th>Short description</th>
                                        <th>
                                            Category
                                        </th>
                                        <th>Created at</th>
                                        <th>Publish</th>
                                        <th style={{textAlign: "center"}}>Edit</th>
                                        <th style={{textAlign: "center"}}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contents &&
                                        <>
                                            {contents.map((content) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {content.title}
                                                        </td>
                                                        <td>
                                                            {content.short_description}
                                                        </td>
                                                        <td>
                                                            {content.Category?.name}
                                                        </td>
                                                        <td>
                                                            {(DateTime.fromISO(content.createdAt)).toISODate(DateTime.DATE_SHORT)}
                                                        </td>
                                                        <td>
                                                            {content.published? "published" : "unpublished"}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {content.Category?.link !== "about-us" &&
                                                                <FontAwesomeIcon icon={faEdit} 
                                                                    onClick={e => {
                                                                        setAction("edit");
                                                                        getContent(content.id);
                                                                        setCreateNewDialog(true);
                                                                    }}
                                                                />
                                                            }                                                            
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {content.Category?.link !== "about-us" && 
                                                                <FontAwesomeIcon icon={faTrash} onClick={e => {
                                                                    setDeleteContentDialog(true)
                                                                    setDeletedId(content.id)
                                                                }} />
                                                            }
                                                            
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
                <Modal show={createNewDialog} onHide={closeCreateNewDialog} size="lg" backdrop>
                    <ModalHeader closeButton>Create new content</ModalHeader>
                    <ModalBody>
                        <Tabs>
                            <Tab title="Tiếng Việt" eventKey="vi">
                                <Container>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Title: 
                                        </Col>
                                        <Col md={7}>
                                            <FormControl value={title} type="text" onChange={e => setTitle(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                        Category: 
                                        </Col>
                                        <Col md={7}>
                                            <FormSelect value={categoryId} onChange={e => setCategoryId(e.target.value)}>
                                                <option value="">Select a category</option>
                                                {cloneCategories &&
                                                    <>
                                                        {cloneCategories.map((category) => {
                                                            return(
                                                                <option value={category.id}>{category.name}</option>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Short Description:
                                        </Col>
                                        <Col md={7}>
                                            <FormControl value={shortDescription} type="text" as="textarea" onChange={e => setShortDescription(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Published:
                                        </Col>
                                        <Col md={4}>
                                            <FormSelect onChange={e => setShouldPublish(e.target.value)} value={shouldPublish}>
                                                <option value={true}>Published</option>
                                                <option value={false}>Unpublished</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Image:
                                        </Col>
                                        <Col md={7}>
                                            <Image onClick={clickUploadImage} thumbnail src={image}/>
                                            <FormControl type="file" style={{visibility: "hidden"}} ref={uploadImageRef} accept=".jpg, .jpeg, .png" onChange={onFileChange}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Content: 
                                        </Col>                                
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={12}>
                                            <ReactQuill theme="snow" 
                                                modules={editorModules}
                                                formats={editorFormats}
                                                onChange={content => setContent(content)}
                                                value={content}
                                                ref={quillRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="en" title="English">
                                <Container>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Title: 
                                        </Col>
                                        <Col md={7}>
                                            <FormControl value={enTitle} type="text" onChange={e => setEnTitle(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                        Category: 
                                        </Col>
                                        <Col md={7}>
                                            <FormSelect value={categoryId} onChange={e => setCategoryId(e.target.value)}>
                                                <option value="">Select a category</option>
                                                {cloneCategories &&
                                                    <>
                                                        {cloneCategories.map((category) => {
                                                            return(
                                                                <option value={category.id}>{category.name}</option>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Short Description:
                                        </Col>
                                        <Col md={7}>
                                            <FormControl value={enShortDescription} type="text" as="textarea" onChange={e => setEnShortDescription(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Published:
                                        </Col>
                                        <Col md={4}>
                                            <FormSelect onChange={e => setShouldPublish(e.target.value)} value={shouldPublish}>
                                                <option value={true}>Published</option>
                                                <option value={false}>Unpublished</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Image:
                                        </Col>
                                        <Col md={7}>
                                            <Image onClick={clickUploadImage} thumbnail src={image}/>
                                            <FormControl type="file" style={{visibility: "hidden"}} ref={uploadImageRef} accept=".jpg, .jpeg, .png" onChange={onFileChange}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={3}>
                                            Content: 
                                        </Col>                                
                                    </Row>
                                    <Row style={{marginTop: "15px"}}>
                                        <Col md={12}>
                                            <ReactQuill theme="snow" 
                                                modules={editorModules}
                                                formats={editorFormats}
                                                onChange={content => setEnContent(content)}
                                                value={enContent}
                                                ref={enQuillRef}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>                            
                        </Tabs>                        
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="danger" onClick={closeCreateNewDialog}>Cancel</Button>
                        <Button variant="primary" onClick={e => {
                            if(action === "new"){
                                createNewContent()
                            }else{
                                updateContent();
                            }
                        }}>Save</Button>
                    </ModalFooter>
                </Modal>
                <Modal show={deleteContentDialog} onHide={closeDeleteContentDialog}>
                    <ModalHeader closeButton>Do you want to delete this content?</ModalHeader>
                    <ModalFooter>
                        <Button variant="danger" onClick={deleteContent}>Yes</Button>
                        <Button variant="secondary" onClick={closeDeleteContentDialog}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 9999
            }}>
                <RingLoader 
                    loading={loading}
                    color="green"
                />
            </div>
        </React.Fragment>
    );
}