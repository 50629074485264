import { sendGetRequest, sendPostRequest } from "./service";

export const loginRequest = async(email, password) => {
    let params = {
        email: email,
        password: password
    };
    return await sendPostRequest("post", "/api/public/user/login", params);
}

export const listUserRequest = async(username, fullName, createdFrom, createdTo, pageIndex, pageSize) => {
    let params = {
        pageIndex: pageIndex,
        pageSize: pageSize
    }
    if(username){
        params.username = username;
    }
    if(fullName){
        params.fullName = fullName;
    }
    if(createdFrom){
        params.createdFrom = createdFrom
    }
    if(createdTo){
        params.createdTo = createdTo
    }
    return sendGetRequest("/api/private/user", params);
}

export const listCvRequest = async(status, fromDate, toDate, pageIndex, pageSize) => {
    let params = {
        pageSize: pageSize,
        pageIndex: pageIndex,
    }
    if(status !== "all"){
        params.status = status;
    }
    if(fromDate){
        params.fromDate = fromDate
    }
    if(toDate){
        params.toDate = toDate;
    }
    return await sendGetRequest("/api/private/caregiver/cvs", params);
}

export const getUserDetailRequest = async() => {
    return await sendGetRequest("/api/private/user/1");
}

export const createAccountRequest = async(username, email, password, fullName) => {
    const params = {
        username: username,
        email: email,
        password: password
    }
    if(fullName){
        params.fullName = fullName;
    }
    return await sendPostRequest("post", "/api/private/user", params);
}

export const resetPasswordRequest = async(userId, password) => {
    return await sendPostRequest("put", "/api/private/user/password", {
        userId: userId,
        password: password
    })
}
export const changePasswordRequest = async(oldPassword, newPassword) => {
    let params = {
        oldPassword: oldPassword,
        newPassword: newPassword
    }
    return await sendPostRequest("put", "/api/private/user/changePassword", params)
}
