import { sendGetRequest, sendPostRequest } from "./service"

export const createNewContentRequest = async(title, content, shortDescription, categoryId, published, fileId,
    enTitle, enContent, enShortDescription) => {
    let params = {
        title: title,
        content: content,
        categoryId: categoryId,
        published: published === "true" ? true : false,
        shortDescription: shortDescription,
        enTitle: enTitle,
        enContent: enContent,
        enShortDescription: enShortDescription
    }
    if(fileId){
        params.fileId = fileId
    }
    return await sendPostRequest("post", "/api/private/content", params);

}

export const listContentRequest = async(searchKey, categoryName, isPublished, pageIndex, pageSize) => {
    let params = {
        pageIndex: pageIndex,
        pageSize: pageSize
    }
    if(searchKey){
        params.searchKey = searchKey
    }
    if(categoryName !== ""){
        params.categoryName = categoryName;
    }
    if(isPublished !== "all"){
        params.published = isPublished === "true" ? true: false;
    }
    return await sendGetRequest("/api/private/content", params);
}

export const getContentRequest = async(id) => {
    let params = {
        id: id
    }
    return await sendGetRequest("/api/private/content/detail", params);
}

export const updateContentRequest = async(contentId, title, shortDesc, content, published, categoryId, 
    contentFileId, fileId, enTitle, enShortDesc, enContent) => {
    let params = {
        contentId: contentId
    }
    if(title){
        params.title = title
    }
    if(shortDesc){
        params.shortDescription = shortDesc
    }
    if(content){
        params.content = content
    }
    if(published){
        params.published = published === "true" ? true : false;
    }
    if(categoryId){
        params.categoryId = categoryId
    }
    if(contentFileId){
        params.contentFileId = contentFileId
    }
    if(fileId){
        params.fileId = fileId
    }
    if(enTitle){
        params.enTitle = enTitle
    }
    if(enShortDesc){
        params.enShortDescription = enShortDesc
    }
    if(enContent){
        params.enContent = enContent
    }
    return await sendPostRequest("put", "/api/private/content", params);

}

export const deleteContentRequest = async(id) => {
    return await sendPostRequest("delete", "/api/private/content", {id: id})
}