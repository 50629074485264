import React, { useState } from "react";
import { Button, Col, Container, FormControl, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../layouts/Header";
import Notification from "../components/Notification"
import PaginationComp from "../components/PaginationComp";
import { listContactRequest } from "../services/contactService";
import { DateTime } from "luxon";
import { useEffect } from "react";

export default function ContactManagemenet() {
    const now = new Date();
    let lastMonth = new Date();
    lastMonth.setDate(lastMonth.getDate() - 30)
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fromDate, setFromDate] = useState(lastMonth);
    const [toDate, setToDate] = useState(now);

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalItem, setTotalItem] = useState(0);
    const [contacts, setContacts] = useState([]);


    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }

    const listContact = async() => {
        let fd = (DateTime.fromJSDate(fromDate)).toISODate(); 
        let td = (DateTime.fromJSDate(toDate)).toISODate(); 
        const data = await listContactRequest(email, phone, fd, td, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setContacts(data.items);
        setTotalItem(data.total);
    }

    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }

    useEffect(() => {
        listContact()
    }, [pageIndex]);

    return(
        <>
            <React.Fragment>
                <Header />
                <div className="main main-app p-3 p-lg-4">
                    <Container fluid>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>
                                From:
                            </Col>
                            <Col md={2}>
                                <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}/>
                            </Col>
                            <Col md={2}>
                                To:
                            </Col>
                            <Col md={2}>
                                <DatePicker selected={toDate} onChange={(date) => setToDate(date)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>Email:</Col>
                            <Col md={6}>
                                <FormControl type="email" onChange={e => setEmail(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={2}>Phone:</Col>
                            <Col md={6}>
                                <FormControl type="text" onChange={e => setPhone(e.target.value)}/>
                            </Col>
                            <Col md={2}>
                                <Button variant="primary" onClick={e => {
                                    if(pageIndex !== 0){
                                        setPageIndex(0)
                                    }else{
                                        listContact();
                                    }
                                }}>Search</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={4} style={{fontWeight: "bold"}}>
                                Total contact: 
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={12}>
                                <Table bordered striped>
                                    <thead style={{fontWeight: "bold"}}>
                                        <tr>
                                            <td>Full name</td>
                                            <td>
                                                Email
                                            </td>
                                            <td>
                                                Phone number
                                            </td>
                                            <td>
                                                Address
                                            </td>
                                            <td>Company</td>
                                            <td>
                                                Created at
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts &&
                                            <>
                                                {contacts.map((contact) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {contact.fullName}
                                                            </td>
                                                            <td>
                                                                {contact.email}
                                                            </td>
                                                            <td>
                                                                {contact.phoneNumber}
                                                            </td>
                                                            <td>
                                                                {contact.address}
                                                            </td>
                                                            <td>
                                                                {contact.company}
                                                            </td>
                                                            <td>
                                                            {(DateTime.fromISO(contact.createdAt)).toISODate(DateTime.DATE_SHORT)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}></Col>
                            <Col md={4}>
                                <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            </React.Fragment>
        </>
    )
}